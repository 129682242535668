import React from 'react';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Services from '../components/Services';
import { useDocTitle } from '../components/CustomHook';

const Home = () => {
    useDocTitle(
        "ARG Digital - TechTransform Pro: Elevating Digital Transformation & Custom Software Development"
    );

    return (
        <>
            <Hero />
            <Intro />
            <Services />
            <Cta />
            <Footer />
        </>
    )
}

export default Home;