import React from 'react';
import startup from '../images/startup.svg';

const BannerAbout = () => {
    return (
        <>
            <div className="hero" id='bannerabout'>
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6">

                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center">
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl">Crafting Digital Excellence from</h1>
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl"><span class="text-blue-600 dark:text-blue-500">Leicester</span></h1>


                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                             Welcome to our corner of the digital world! We're a dynamic startup based in the heart of Leicester, where innovation meets passion. At ARG Digital, we're not just developers; we're dreamers and creators who translate ideas into immersive digital experiences.
                            </div>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                With a focused team of seasoned experts in website and mobile application development, cloud services, DevOps, and application testing, we're here to empower businesses of all scales – from small startups to thriving enterprises.
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2">
                            <img alt="Empowering businesses with innovative software solutions tailored to their needs." className="rounded-t float-right md:float-right lg:float-right mx-auto sm:mx-auto duration-1000 w-9/12" src={startup} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerAbout;