import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import Hero from '../components/Hero';
import { Helmet } from 'react-helmet';

const WhyUs = (props) => {
    return (
        <>
            <Helmet>
                <title>ARG Digital | Web and App Development Company Based on Leicester</title>
            </Helmet>
            <NavBar />
            <Hero />
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WhyUs;