import React from 'react';

const MoreAboutUs = () => {
    return (
        <>
            <div className="pb-16 max-w-7xl m-auto" id='MoreAboutUs'>

                <div className="px-4">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">                            
                        <div className="bg-white overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-6">
                            <div className="m-2text-sm">
                                <h1 class="text-4xl font-extrabold text-blue-900">What Sets Us Apart</h1>
                                <h1 class="text-2xl font-extrabold text-blue-900"><small class="font-semibold text-gray-500 dark:text-gray-400">Expertise and Innovation</small></h1>
                                <p className="text-md font-medium leading-normal pt-4 h-auto text-gray-600">
                                    In the rapidly evolving landscape of technology, our team stands as a beacon of expertise and innovation. From harnessing the potential of ReactJS, ReactNative, and Angular to building robust backends with NodeJS, Java Springboot, and PHP, we've honed our skills to create seamless digital solutions that cater to every need. We're not just limited to coding; we've mastered the intricacies of AWS, GCP, Serverless architecture, Kubernetes, Automation, Jenkins, and much more.
                                </p>
                            </div>
                        </div>

                        <div className="bg-whiteoverflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-6">
                            <div className="m-2text-sm">
                                <h1 class="text-4xl font-extrabold text-blue-900">Our Focus</h1>
                                <h1 class="text-2xl font-extrabold text-blue-900"><small class="font-semibold text-gray-500 dark:text-gray-400">Tailored Solutions for Every Venture</small></h1>
                                <p className="text-md font-medium text-justify leading-normal pt-4 h-auto text-gray-600">
                                    Whether you're a budding startup, a small-scale enterprise, or an established industry player, we've got a solution designed just for you. We believe that one size does not fit all, and that's why we take a tailored approach to every project. Your vision becomes our mission, and we work hand-in-hand with you to transform your ideas into reality. Our solutions aren't just functional; they're designed to leave a lasting impression on your audience, driving engagement, and boosting your bottom line.
                                </p>
                            </div>
                        </div>

                        <div className="bg-whiteoverflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-6">
                            <div className="m-2text-sm">
                                <h1 class="text-4xl font-extrabold text-blue-900">Our Promise</h1>
                                <h1 class="text-2xl font-extrabold text-blue-900"><small class="font-semibold text-gray-500 dark:text-gray-400">Excellence, Every Step of the Way</small></h1>
                                <p className="text-md font-medium text-justify leading-normal pt-4 h-auto text-gray-600">
                                    At ARG Digital, we don't just create products; we create experiences. From the initial brainstorming session to the final lines of code, our team is committed to excellence every step of the way. We take pride in our attention to detail, our obsession with quality, and our dedication to exceeding your expectations. Our success isn't just measured in completed projects; it's measured in the smiles of satisfied clients who've seen their visions come to life.
                                </p>
                            </div>
                        </div>

                                      
                    </div>
                </div>

                {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 text-blue-900">Join Us on the Digital Journey</h4>
                                <p className="text-md font-medium text-justify leading-normal pt-4 h-auto md:h-48">
                                    We invite you to join us on a journey through the digital realm, where innovation knows no bounds. Whether you're looking to revamp your online presence, create a cutting-edge mobile application, or streamline your operations with the latest cloud services, ARG Digital is here to make it happen.    
                                </p>
                            </div>
                        </div>       */}
            </div>
        </>
    )
}

export default MoreAboutUs;