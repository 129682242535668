import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import search from '../images/404.svg';
import Cta from '../components/Cta';
import { Helmet } from 'react-helmet';


const NotFound = (props) => {
    return (
        <>
            <Helmet>
                <title>404 - Page Not Found | ARG Digital</title>
            </Helmet>
            <NavBar />
            
            <div className="m-auto max-w-7xl p-2 md:p-12 h-5/6">
                <div className="justify-center">
                    <img alt="page not found" className="m-auto max-w-full rounded-t object-center lg:max-w-3xl	 xl:max-w-3xl 2xl:max-w-3xl" src={search} />
                </div>

                <div class="flex flex-auto items-center justify-center text-center px-4 flex-col sm:flex-row">
                    <h1 class="text-2xl sm:text-3xl font-extrabold text-blue-900 tracking-tight sm:pr-6 sm:mr-6 sm:border-r sm:border-blue-900/10">404</h1>
                    <h2 class="mt-2 text-lg  text-blue-600 sm:mt-0">This page could not be found.</h2>
                </div>
            </div>


            <Cta/>
            <Footer />
        </>
    )
}

export default NotFound;