import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import BannerServices from '../components/BannerServices';
import ServiceList from '../components/ServiceList';
import Cta from '../components/Cta';
import { Helmet } from 'react-helmet';

const Services = (props) => {
    return (
        <>
            <Helmet>
                <title>ARG Digital | Leading Digital Transformation Services Provider in the UK</title>
            </Helmet>
            <NavBar />
            <BannerServices />
            <ServiceList />
            <Cta/>
            <Footer />
        </>
    )
}

export default Services;