import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/it-solutions.svg';

const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6">

                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center">
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl">Empower Your Future Today</h1>
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl"><span class="text-blue-600 dark:text-blue-500">Embrace Digital Transformation with Our Expertise!</span></h1>

                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                            A leading web and app development company, we specialize in turning innovative ideas into reality and build digital solutions to help businesses scale.
                            </div>
                        </div>

                        <div className="flex lg:justify-end w-full lg:w-1/2">
                            <img alt="Discover Our Cutting-Edge Software Solutions for Businesses of All Sizes." className="rounded-t float-right mx-auto sm:mx-auto w-4/5 duration-1000" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;