import React from 'react';
import team from '../images/it-services.svg';

const BannerServices = () => {
    return (
        <>
            <div className="hero" id='BannerServices'>
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6">

                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center">
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl">Your Pathway to Digital Excellence</h1>
                            <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl"><span class="text-blue-600 dark:text-blue-500">Unleash the Power of Possibilities</span></h1>

                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                Crafting immersive websites to seamless mobile experiences, harnessing the cloud, and offering strategic IT consulting, we're your compass in the ever-evolving world of technology. Step into the realm of boundless possibilities – step into digital excellence.
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2">
                            <img alt="Our skilled developers working collaboratively on innovative software solutions." className="rounded-t float-right md:float-right lg:float-right mx-auto sm:mx-auto duration-1000" src={team} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerServices;