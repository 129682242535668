import React from 'react';
import WebDevelopment from '../images/web-development.svg';
import MobileDevelopment from '../images/app-devlopment.svg';
import CloudService from '../images/cloud-infra.svg';
import Consultation from '../images/discussion.svg';

const ServiceList = () => {
    return (
      <>
        <div className="m-auto max-w-7xl p-2 md:p-12 h-5/6">
            <div className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
                <div className="flex items-center justify-center w-full lg:w-1/2 right">
                    <img alt="Creating intuitive UI/UX designs for user-centric software experiences." className="max-w-md rounded-t object-center" src={WebDevelopment} />
                </div>
                <div className="flex flex-wrap items-center w-full lg:w-1/2 right">
                    <div className="flex flex-col w-full mt-4">
                        <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl">Web Development</h3>
                        <p className="max-w-2xl py-4 text-lg leading-normal lg:text-xl xl:text-xl text-gray-500 dark:text-gray-400">
                            Crafting Digital Experiences that Captivate and Inspire
                        </p>

                        <div className="w-full mt-5 align-top">
                            {/* <p>
                            In the digital age, your website is more than just an online presence – it's a reflection of your brand's identity and values. At ARG Digital, we specialize in creating captivating and user-centric websites that leave a lasting impression. Our website development services encompass a wide range of technologies and design principles to ensure your digital footprint is nothing short of extraordinary.

                            From intuitive navigation to visually appealing layouts, our expert team of developers, designers, and UX/UI specialists work in harmony to bring your vision to life. Whether you're seeking an informative platform or a robust e-commerce solution, we tailor our approach to meet your unique requirements. With seamless responsiveness and optimal performance, we guarantee a website that engages users and drives conversions.

                            Explore our website development services and let us elevate your online presence to new heights.
                            </p> */}

                            <ul class="list-none leading-8 list-outside text-gray-600 text-base">
                                <li>Create captivating and user-centric websites that reflect your brand's identity.</li>
                                <li>Expertise in a wide range of technologies and design principles.</li>
                                <li>Develop intuitive navigation and visually appealing layouts.</li>
                                <li>Expert team of developers, designers, and UX/UI specialists.</li>
                                <li>Tailored approach to meet unique requirements.</li>
                                <li>Seamless responsiveness and optimal performance.</li>
                                <li>Elevate your online presence with our website development services.</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
                <div className="flex items-center justify-center w-full lg:w-1/2 lg:order-1">
                    <img alt="Crafting tailored software solutions to meet unique business needs." className="max-w-md rounded-t object-center" src={MobileDevelopment} />
                </div>
                <div className="flex flex-wrap items-center w-full lg:w-1/2 lg:justify-end">
                    <div className="flex flex-col w-full mt-4">
                        <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl">Mobile App Development</h3>
                        <p className="max-w-2xl py-4 text-lg leading-normal lg:text-xl xl:text-xl text-gray-500 dark:text-gray-400">
                            Seamless Mobile Solutions for an Engaging Digital Presence
                        </p>

                        <div className="w-full mt-5 align-top">
                            {/* <p>
                            In a world that's increasingly mobile-oriented, a well-crafted mobile app can set your business apart. At ARG Digital, we specialize in mobile app development that seamlessly integrates innovation and functionality. Our mobile app solutions are designed to cater to diverse platforms, ensuring your app is accessible to a wide audience.

                            From conceptualization to design and development, our team is dedicated to creating apps that not only align with your business goals but also provide users with an intuitive and engaging experience. We harness the power of cutting-edge technologies to build apps that are robust, scalable, and ready to adapt to the evolving demands of the digital landscape.

                            Discover our mobile app development services and let us transform your app ideas into reality.
                            </p> */}

                            <ul class="list-none leading-8 list-outside text-gray-600 text-base">
                                <li>Specialize in mobile app development for a mobile-oriented world.</li>
                                <li>Seamlessly integrate innovation and functionality.</li>
                                <li>Develop apps for diverse platforms, ensuring accessibility.</li>
                                <li>Dedicated team for conceptualization, design, and development.</li>
                                <li>Create intuitive and engaging user experiences.</li>
                                <li>Harness cutting-edge technologies for robust and scalable apps.</li>
                                <li>Transform your app ideas into reality with our mobile app development services.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
                <div className="flex items-center justify-center w-full lg:w-1/2 right">
                    <img alt="Delivering cloud-based solutions that scale with your business growth." className="max-w-md rounded-t object-center" src={CloudService} />
                </div>
                <div className="flex flex-wrap items-center w-full lg:w-1/2 right">
                    <div className="flex flex-col w-full mt-4">
                        <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl">DevOps and Cloud Services</h3>
                        <p className="max-w-2xl py-4 text-lg leading-normal lg:text-xl xl:text-xl text-gray-500 dark:text-gray-400">
                        Elevate Efficiency and Innovation with Cloud-Powered DevOps
                        </p>

                        <div className="w-full mt-5 align-top">
                            {/* <p>
                            In the era of digital transformation, leveraging cloud services and DevOps practices can redefine the way you operate. At ARG Digital, we're adept at helping businesses harness the power of the cloud and streamline their operations through DevOps methodologies.

                            Our experts analyze your existing infrastructure, design tailored cloud solutions, and implement DevOps practices to enhance efficiency and collaboration. Whether it's AWS, GCP, or other leading cloud platforms, we ensure seamless integration, scalability, and security. From automated deployments to continuous monitoring, our DevOps approach optimizes your development and operational processes, paving the way for rapid innovation and growth.

                            Explore our DevOps and cloud services and embark on a journey of streamlined efficiency and enhanced agility.
                            </p> */}

                            <ul class="list-none leading-8 list-outside text-gray-600 text-base">
                                <li>Leverage cloud services and DevOps practices for digital transformation.</li>
                                <li>Help businesses harness the power of the cloud.</li>
                                <li>Streamline operations through DevOps methodologies.</li>
                                <li>Design tailored cloud solutions for efficiency and collaboration.</li>
                                <li>Seamless integration of AWS, GCP, and other leading cloud platforms.</li>
                                <li>Automated deployments and continuous monitoring for rapid innovation.</li>
                                <li>Explore our DevOps and cloud services for enhanced agility.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
                <div className="flex items-center justify-center w-full lg:w-1/2 lg:order-1">
                    <img alt="Architecting software solutions for scalability and future growth." className="max-w-md rounded-t object-center" src={Consultation} />
                </div>
                <div className="flex flex-wrap items-center w-full lg:w-1/2 lg:justify-end">
                    <div className="flex flex-col w-full mt-4">
                        <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl">General IT Consulting</h3>
                        <p className="max-w-2xl py-4 text-lg leading-normal lg:text-xl xl:text-xl text-gray-500 dark:text-gray-400">
                            Strategic Guidance for Navigating the Digital Frontier
                        </p>

                        <div className="w-full mt-5 align-top">
                            {/* <p>
                            Navigating the intricate landscape of technology requires insight and expertise. At ARG Digital, our general IT consulting services provide businesses with strategic guidance and practical solutions. We partner with you to identify challenges, uncover opportunities, and devise tailored strategies that drive digital success.

                            Whether you're looking to enhance cybersecurity, optimize infrastructure, or implement the latest technologies, our experienced consultants offer insights that align with your goals and industry best practices. From small-scale businesses to enterprises, we bring our extensive experience to the table, helping you make informed decisions that accelerate growth.

                            Discover our general IT consulting services and let us be your trusted partner in your digital journey.
                            </p> */}

                            <ul class="list-none leading-8 list-outside text-gray-600 text-base">
                                <li>Provide businesses with strategic guidance and practical solutions.</li>
                                <li>Navigate the intricate landscape of technology.</li>
                                <li>Identify challenges, uncover opportunities, and devise tailored strategies.</li>
                                <li>Enhance cybersecurity, optimize infrastructure, and implement technologies.</li>
                                <li>Experienced consultants offer insights aligned with goals and industry best practices.</li>
                                <li>Trusted partner for making informed decisions and accelerating growth.</li>
                                <li>Discover our general IT consulting services for your digital journey.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
};

export default ServiceList;