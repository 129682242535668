import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import dev from '../images/dev.svg';
import BannerAbout from '../components/BannerAbout';
import MoreAboutUs from '../components/MoreAboutUs';
import Cta from '../components/Cta';
import { Helmet } from 'react-helmet';

const About = (props) => {
    return (
        <>
            <Helmet>
                <title>ARG Digital | Professional Website, Mobile App and Custom Software Development Company</title>
            </Helmet>
            <NavBar />
            <BannerAbout />
            <div className="m-auto max-w-7xl p-2 md:p-12 h-5/6">
                <div className="flex justify-center items-center w-full bg-white">
                    <div className="container mx-auto my-8 px-4 lg:px-20">

                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">
                            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                                <img alt="Empowering UK Businesses: Innovating through Software and App Development" className="max-w-md rounded-t object-center" src={dev} />
                            </div>
                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8">

                                <h1 class="mb-4 text-3xl font-extrabold leading-none tracking-tight text-blue-900 md:text-4xl lg:text-5xl">Our Journey <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">Forging a Path of Innovation</span></h1>

                                <div class="pt-3">
                                    <p className='my-3 text-xl text-gray-600'>ARG Digital was born out of a shared vision among a close-knit group of developers who believed in the power of technology to reshape the way businesses connect with their audiences.</p>
                                </div>
                                
                                <div>
                                    <p className='my-3 text-xl text-gray-600'>With a combined experience of over 15 years in the industry, we've evolved from a tight group of enthusiasts into a force to be reckoned with. Our journey has been fueled by curiosity, a thirst for learning, and an unwavering commitment to staying ahead of the digital curve.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MoreAboutUs />
            <Cta/>
            <Footer />
        </>
    )
}

export default About;