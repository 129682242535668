import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const EmailForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);
    const [notification, setNotification] = useState('');
    const [formData, setFormData] = useState({
       firstname: '',
       lastname: '',
       email: '',
       phone: '',
       message: '',
    });

    const [errors, setErrors] = useState({
       firstname: '',
       lastname: '',
       email: '',
       phone: '',
       message: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    
        // Clear the error for the current input
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
    };

    const validateEmail = (email) => {
       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[\d+]{8,14}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (event) => {
       event.preventDefault();
       setIsSubmitting(true); // Set loading state to true

       const newErrors = { ...errors };

       if (!formData.firstname.trim()) {
            newErrors.firstname = 'First name is required';
       } else {
            newErrors.firstname = '';
       }

       if (!formData.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
       } else {
            newErrors.lastname = '';
       }

       if (!validateEmail(formData.email)) {
            newErrors.email = 'Invalid email address';
       } else {
            newErrors.email = '';
       }

       if (!validatePhone(formData.phone)) {
            newErrors.phone = 'Invalid phone number';
       } else {
            newErrors.phone = '';
       }

       if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
       } else {
            newErrors.message = '';
       }

       setErrors(newErrors);

       // If there are no errors, proceed with sending email
       if (Object.values(newErrors).every((error) => !error) && isCaptchaSuccessful) {
         try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL,
                formData
            );

            console.log(response.data);

            // Reset form after successful submission
            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
            });

            // Show success notification
            setNotification('Form submitted successfully!');

            // Set loading state to false after successful submission
            setIsSubmitting(false);
         } catch (error) {
            console.error('Error sending email:', error);
            // Show error notification
            setNotification('An error occurred. Please try again later.');

            // Set loading state to false if there's an error
            setIsSubmitting(false);
         }
       }
    };

    function onChange(value) {
        setIsCaptchaSuccess(true)
        console.log("captcha value: ", value);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div className="flex">
                    <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Send us a message</h1>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    <div>
                        <input 
                            name="firstname" 
                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="text" 
                            placeholder="First Name*" 
                            value={formData.firstname}
                            onChange={handleInputChange}
                        />
                        {errors.firstname && 
                            <p className="text-red-500 text-sm">{errors.firstname}</p>
                        }
                    </div>
                        
                    <div>
                        <input 
                            name="lastname" 
                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="text" 
                            placeholder="Last Name*"
                            value={formData.lastname}
                            onChange={handleInputChange}
                        />
                        {errors.lastname && 
                            <p className="text-red-500 text-sm">{errors.lastname}</p>
                        }
                    </div>

                    <div>
                        <input 
                            name="email"
                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="email" 
                            placeholder="Email*"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && 
                            <p className="text-red-500 text-sm">{errors.email}</p>
                        }
                    </div>

                    <div>
                        <input
                            name="phone" 
                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="number" 
                            placeholder="Phone*"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                        {errors.phone && 
                            <p className="text-red-500 text-sm">{errors.phone}</p>
                        }
                    </div>
                </div>
                <div className="my-4">
                    <textarea 
                        name="message" 
                        placeholder="Message*" 
                        className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                        value={formData.message}
                        onChange={handleInputChange}
                    ></textarea>
                    {errors.message && 
                        <p className="text-red-500 text-sm">{errors.message}</p>
                    }
                </div>
                {!isCaptchaSuccessful && (
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                />)}
                {/* Display notification */}
                {notification && (
                    <div className="my-4">
                        <p className={`text-sm ${notification.startsWith('Form submitted') ? 'text-green-500' : 'text-red-500'}`}>
                        {notification}
                        </p>
                    </div>
                )}
                <div className="my-2 w-1/2 lg:w-2/4">
                    <button
                        type="submit"
                        disabled={!isCaptchaSuccessful || isSubmitting} // Disable the button during submission
                        className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                    >
                        {isSubmitting ? (
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-900"></div>
                                <span className="ml-2">Sending...</span>
                            </div>
                        ) : (
                            'Send Message'
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default EmailForm;
